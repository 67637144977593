import {
  type DocumentNode,
  type QueryHookOptions,
  useQuery,
  OperationVariables,
  ApolloQueryResult,
  ApolloError,
  TypedDocumentNode,
} from '@apollo/client'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import { getByPathWithDefault } from 'utils/fp'
import { trackingError } from 'utils/trackingError'

import { FieldValue, Hit, Scalars } from '@graphql/server/typescript'

import { Entity, nonNullable, ToDistArray } from '@types'
import { isUnretrievable } from '@utils/data'

type ListResult<TData, TNode> = {
  nodes: ToDistArray<TNode> // ensure that union node types are distributed to separate array of node union types
  totalCount: number | null
  hits: Hit[]
  hitsIDs: Scalars['ID'][]
  loading: boolean
  hasMore: boolean
  loadMore: () => Promise<void | ApolloQueryResult<TData>>
  loadNMore: (n: number) => Promise<void | ApolloQueryResult<TData>[]>
  loadAll: () => Promise<void | ApolloQueryResult<TData>[]>
  error: ApolloError | undefined
  data: TData | undefined
}

/**
 * !BEWARE handing network-policy: "no-cache", it seems to cause apollo-client/issues/6916
 */
export default function useQueryList<
  TData extends Record<string, any>,
  TVariable extends OperationVariables & { page?: number; perPage?: number },
  TResultPath extends Exclude<keyof TData, '__typename'> & string = Exclude<
    keyof TData,
    '__typename'
  > &
    string,
  TNode = TData[TResultPath] extends { nodes: (infer U)[] } ? U : never
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariable>,
  fetchOptions: QueryHookOptions<TData, TVariable>,
  resultPath: TResultPath,
  hookOptions?: { disableReset?: boolean }
): ListResult<TData, TNode> {
  const intl = useIntl()

  const isFetchingRef = useRef(true) // We use a ref as a consumer of this API could spam many calls using DOM itself (eg scroll element) BEFORE React has caught up its renders (very edge-case scenario but happens)
  const [isFetching, setIsFetching] = useState(true) // ... and we use a state as well to ensure that all state is up-to-date before allowing another call
  const [requestedPage, setRequestedPage] = useState<number>(1)

  const reset = () => {
    setRequestedPage(1)
  }

  useEffect(() => {
    if (!hookOptions?.disableReset) reset()
  }, [
    fetchOptions.variables?.filterBy,
    fetchOptions.variables?.sortBy,
    fetchOptions.variables?.page,
    fetchOptions.variables?.perPage,
    hookOptions?.disableReset,
    query,
  ])

  const { data, loading, fetchMore, error } = useQuery<TData, TVariable>(query, {
    ...fetchOptions,
    onError: (err) => {
      trackingError(err)
      toast.error(
        intl.formatMessage({
          id: 'global.apiErrorMessage',
          defaultMessage: 'There was an error. Please try again later.',
        })
      )
    },
  })

  const nodes: ToDistArray<TNode> = useMemo(
    () => getByPathWithDefault([], `${resultPath}.nodes`, data),
    [data, resultPath]
  )
  const totalCount: number | null = useMemo(
    () => getByPathWithDefault(null, `${resultPath}.totalCount`, data),
    [data, resultPath]
  )
  const hits: Hit[] = useMemo(
    () => getByPathWithDefault([], `${resultPath}.hits`, data),
    [data, resultPath]
  )
  const hitsIDs: Scalars['ID'][] = useMemo(
    () =>
      hits.flatMap((hit) =>
        hit.entityHits
          .map((eh) => eh.entity)
          .filter(
            (e): e is Exclude<Entity, FieldValue> =>
              !isUnretrievable(e) && e.__typename !== 'FieldValue'
          )
          .map((entity) => entity.id)
      ),
    [hits]
  )
  const page: number = useMemo(
    () => getByPathWithDefault(1, `${resultPath}.page`, data),
    [data, resultPath]
  )
  const totalPage: number = useMemo(
    () => getByPathWithDefault(1, `${resultPath}.totalPage`, data),
    [data, resultPath]
  )

  /**
   * We need to wait for merging to complete, which for now we only know how to confirm by checking
   * the page in the result of the query: Waiting for a promise to resolve does not guarantee the cache
   * has finished recalculating its state.
   */
  useEffect(() => {
    if (page === requestedPage && isFetchingRef.current === true) {
      isFetchingRef.current = false
      setIsFetching(false) // Without this, you will not ensure that other states are up to date
    }
  }, [page, requestedPage])

  const hasMore = page < totalPage

  const loadMore = useCallback(() => {
    if (isFetchingRef.current === true || isFetching || !fetchOptions.variables || !hasMore)
      return Promise.resolve()
    isFetchingRef.current = true
    setIsFetching(true)
    const nextPage = page + 1
    setRequestedPage(nextPage)

    return fetchMore<TData, TVariable>({
      variables: { ...fetchOptions.variables, page: nextPage },
    }).catch((err) => {
      trackingError(err)
    })
  }, [isFetching, fetchMore, fetchOptions, page, setRequestedPage, hasMore])

  /** load N more pages of `options.variables.perPage` */
  const loadNMore = useCallback(
    (n: number) => {
      const nextPages = Array.from(Array(n).keys())
        .map((_n) => page + _n + 1)
        .filter((_n) => _n <= totalPage)
      const lastNextPage = nextPages[nextPages.length - 1]
      if (!lastNextPage || isFetchingRef.current === true || !data || !hasMore)
        return Promise.resolve()

      setRequestedPage(lastNextPage)
      isFetchingRef.current = true

      return Promise.all(
        nextPages
          .map((nextPage) => {
            if (!fetchOptions.variables) return

            return fetchMore<TData, TVariable>({
              variables: { ...fetchOptions.variables, page: nextPage },
            })
          })
          .filter(nonNullable)
      )
    },
    [data, fetchMore, fetchOptions, page, totalPage, setRequestedPage, hasMore]
  )

  const loadAll = useCallback(() => loadNMore(totalPage - page), [loadNMore, page, totalPage])

  return {
    data,
    nodes,
    hits,
    hitsIDs,
    totalCount,
    loading,
    hasMore,
    loadMore,
    loadNMore,
    loadAll,
    error,
  }
}
